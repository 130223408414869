import React from "react";

import { RenderBodyArgs } from "gatsby";
import { themeAtomKey } from "@/hooks";

import config from "../../content/config.json";

const onRenderBody = ({
  setHtmlAttributes,
  setHeadComponents,
  setPreBodyComponents,
}: RenderBodyArgs) => {

  if (config.plausibleScriptUrl) {
    setHeadComponents([
      React.createElement("link", {
        key: "plausible-preload",
        rel: "preload",
        as: "script",
        href: config.plausibleScriptUrl,
      }),
    ]);
  }

  const components = [];

  components.push(
    React.createElement("script", {
      key: "theme",
      dangerouslySetInnerHTML: {
        __html: `
          void function() {
            var cachedMode;

            try {
              var preferredTheme = JSON.parse(localStorage.getItem('${themeAtomKey}'));

              if (preferredTheme && preferredTheme.mode) {
                cachedMode = preferredTheme.mode;
              }
            } catch (err) { }

            function setTheme(newTheme) {
              document.documentElement.className = newTheme;
            }

            var darkQuery = window.matchMedia('(prefers-color-scheme: dark)');

            setTheme(cachedMode || (darkQuery.matches ? 'dark' : 'light'));
          }()
        `,
      },
    })
  );

  if (config.plausibleScriptUrl) {
    components.push(
      React.createElement("script", {
        key: "plausible-script",
        defer: true,
        src: config.plausibleScriptUrl,
        "data-domain": config.plausibleTrackedDomain,
      })
    );

    components.push(
      React.createElement("script", {
        key: "plausible-custom-events",
        dangerouslySetInnerHTML: {
          __html: `
            window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) };
          `,
        },
      })
    );
  }

  setPreBodyComponents(components);
  setHtmlAttributes({ lang: "en" });
};

export { onRenderBody };
